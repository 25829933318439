import React from "react";
import { Helmet } from "react-helmet";

export const HelmetApp = ({ title, des='' }) => {

    return (
        <div className="application">
            <Helmet>
                <meta charSet="utf-8" />
                <title>{title}</title>
                {/* {des && <meta name="description" content={des} />} */}
                
               <meta name="description" content={des}/>
            </Helmet>
        </div>
    );
};