import * as React from 'react';
// import styled from '@emotion/styled';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// const ExpandMoreIcon = ''
import './accordinBox.css'

export default function ControlledAccordions() {
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <div className=''>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    className='accordinItem'
                >
                    <Typography sx={{ width: '80%', flexShrink: 0 }}>
                        Quality control is a vital aspect of our company.
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        Quality control is a vital aspect of our company. We adhere to strict standards and regulations to ensure that the products meet the highest quality and safety standards. This is achieved through rigorous testing, analysis, and evaluation of the  products.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    className='accordinItem'
                >
                    <Typography sx={{ width: '80%', flexShrink: 0 }}>
                        Our quality control lab is responsible for ensuring that the products
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        Our quality control lab is responsible for ensuring that the products produced meet the required quality standards. The lab conducts various tests and analyses to assess the purity, potency, and safety of the products.
                    </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    className='accordinItem'
                >
                    <Typography sx={{ width: '80%', flexShrink: 0 }}>
                        Some of the common quality control tests performed in our labs include:
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>

                        1. Gas Chromatography (GC): This technique helps in identifying and quantifying the different chemical components present in the products, which is crucial for determining the authenticity and purity.
                        <br />        <br />
                        2. Mass Spectrometry (MS): MS is often used in conjunction with GC to provide further identification and quantification of the chemical constituents in the essential oils. It helps in confirming the presence of specific compounds and detecting any potential contaminants.
                        <br />        <br />
                        3. Physical and Organoleptic tests: These involve evaluating the appearance, color, odor, and consistency of the products. Any abnormality or deviation from the standard is noted.
                        <br />        <br />
                        
                        4. Stability testing: It involves subjecting the products to different environmental conditions (such as temperature, light, and humidity) to assess their shelf life and stability over time.
                        <br />        <br />
                        6. Quality assurance documentation: Our lab also generates and maintains proper records and documentation that prove compliance with regulatory standards and provide traceability for each batch.


                    </Typography>
                </AccordionDetails>
            </Accordion>

        </div>
    );
}