import React from 'react'
import { FaCalculator, FaFacebook, FaInstagram, FaLinkedin, FaWhatsapp, FaYoutube } from 'react-icons/fa'
import { MdAlternateEmail, MdOutlineArrowRight, MdOutlinePhonelinkRing } from 'react-icons/md'
import { BsSend } from 'react-icons/bs'
import './footer.css'
import { ImLocation2 } from 'react-icons/im'


const Footer = () => {
    return (
        <footer>
            <div className="footerContainer">

                <div className="subscribeBox">
                    <h2>Contact Now !</h2>
                    {/* <div className='subscribeInp'>
                    <input type="text" placeholder='Email' />
                    <BsSend/>
                </div> */}
                    <div className="downRightBox ">
                        <a href="https://instagram.com/ghaziabadaromatics?igshid=NGVhN2U2NjQ0Yg==" target="_blanck">
                            <FaInstagram />
                        </a>
                        <a href="https://www.linkedin.com/company/ghaziabad-aromatics/" target="_blanck">
                            <FaLinkedin />
                        </a>
                        {/* <a href="/" target="_blanck">
                            <FaFacebook />
                        </a> */}
                        <a rel="noreferrer" target='_blank' href={"https://wa.me/+918178948098"}><FaWhatsapp /></a>
                    </div>
                    <div className='contactBoxs'>
                        <FaCalculator />
                        <span>
                            <p>Call us 24/7 :</p>
                            <div className='ptag'><a rel="noreferrer" target='_blank' href={"tel:+918178948098"}>91 8178948098</a></div>
                        </span>
                    </div>
                </div>
                <div className="topBox">
                    <div className="">
                        <div>
                            {/* <p ><b className="" >GHAZIABAD AROMATIC</b></p> <br /> */}
                            <img src={require('../images/ga-logo.jpg')} alt="" width={120} className='logo' />
                            <div className='ptag'> <ImLocation2 />  Devi Mandir Compound, Opposite Delhi Gate, Ghaziabad.</div> <br />
                            {/* <p>152, 1st floor, Bazar Ajmeri, Gate, Delhi-6 </p><br /> */}
                            <p>For Genral query :</p>

                            <div className='ptag'>
                                <a rel="noreferrer" target='_blank' href={"tel:+918178948098"}><MdOutlinePhonelinkRing /> +91 8178948098</a>
                            </div>
                            <div className='ptag'> <a rel="noreferrer" target='_blank' href={"mailto:sales@ghaziabadaromatics.com"}> <MdAlternateEmail /> sales@ghaziabadaromatics.com</a></div>
                            <div className='ptag'> <a rel="noreferrer" target='_blank' href={"mailto:info@ghaziabadaromatics.com"}> <MdAlternateEmail /> info@ghaziabadaromatics.com</a></div>
                        </div>
                    </div>
                    <div className="">
                        <div className="Box2heading">Important Links</div>
                        <div><a className="atag" href="/"><MdOutlineArrowRight />   Home</a></div>
                        <div><a className="atag" href="/products"> <MdOutlineArrowRight />  Products</a></div>
                        <div><a className="atag" href="/company"><MdOutlineArrowRight /> About us</a></div>
                        <div><a className="atag" href="/contact"><MdOutlineArrowRight />  Contact us</a></div>
                    </div>
                    {/* <div className="">
                        <div className="Box2heading">Categories</div>
                        <div><a className="atag" href="/products"><MdOutlineArrowRight /> Essential Oils</a></div>
                        <div><a className="atag" href=""> <MdOutlineArrowRight />  Carrier Oils</a></div>
                        <div><a className="atag" href="/about"><MdOutlineArrowRight /> 	Aromatic Chemicals</a></div>
                        <div><a className="atag" href="/contact"><MdOutlineArrowRight />  Plant Extracts</a></div>
                    </div>
                    <div className="">
                        <div className="Box2heading">Other </div>
                        <div><a className="atag" href="/products"><MdOutlineArrowRight />Oleoresins</a></div>
                        <div><a className="atag" href=""> <MdOutlineArrowRight />  	Flavours </a></div>
                        <div><a className="atag" href="/about"><MdOutlineArrowRight /> 	Fragrances</a></div>
                        <div><a className="atag" href="/contact"><MdOutlineArrowRight />  Contact us</a></div>
                    </div> */}
                    <div className="box3">
                        <div className="Box2headin"></div>

                        <div className="logoBox">
                            {/* <img src={require('../images/logo2.png')} alt="logo" /> */}
                            <h1>GHAZIABAD AROMATICS</h1> <br />
                            <p>We aim at providing natural products to the world</p>
                        </div>

                    </div>
                </div>
                {/* 
                <div className="downBox">
                    <div className="downLeftBox">
                        <p>© Copyright <b>SAWW MASTER TOOL</b>. All Rights Reserved</p>
                    </div>
                    <div className="downRightBox ">
                        <a href="https://www.instagram.com/sawmastertools/" target="_blanck">
                            <FaInstagram />
                        </a>
                        <a href="https://www.facebook.com/profile.php?id=100095300904557" target="_blanck">
                            <FaFacebook />
                        </a>
                        <a href="https://www.youtube.com/@SawMasterTools" target="_blanck">
                            <FaYoutube />
                        </a><a rel="noreferrer" target='_blank' href={"https://wa.me/9811826827"}><FaWhatsapp /></a>
                    </div>
                </div> */}
            </div>

        </footer>
    )
}

export default Footer