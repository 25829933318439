import './loading.css'
import MetaData from '../Components/MetaData'

import React from 'react'

const Loading = () => {
  return (
    <div className='loading'>
      {/* <MetaData title="" /> */}
        <div></div>
    </div>
  )
}

export default Loading