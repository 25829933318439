import React, { useState } from "react";
import { FaInstagram, FaLinkedin, FaWhatsapp } from "react-icons/fa";
import { CgPhone } from "react-icons/cg";
import { BiMenuAltRight } from "react-icons/bi";
import "./Header.css";
import { Link, NavLink} from "react-router-dom";
import { getCategories } from "../actions/productAction";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Breadcrumbs } from 'react-breadcrumbs-dynamic'



const Header = ({ active }) => {
  const [isHide, setisHide] = useState(true);
  const dispatch = useDispatch();

  window.scrollTo(0, 0)
  // console.log(categories)
  // const categories=[]
  useEffect(() => {
    dispatch(getCategories())
  }, [dispatch])


  

  const menuHandler = (e) => {
    if (isHide) {
      document
        .getElementsByClassName("menuContainer")[0]
        .classList.remove("hide");
      document.getElementsByClassName("menuContainer")[0].classList.add("show");
      setisHide(false);
    } else {
      document.getElementsByClassName("menuContainer")[0].classList.add("hide");
      document
        .getElementsByClassName("menuContainer")[0]
        .classList.remove("show");

      setisHide(true);
    }
  };
  //---------------------
  const downNav = document.getElementsByClassName("downNav");

  const mouseEnter = (num) => {
    downNav[num].classList.remove("downNavHide");
  };
  const mouseLeave = (num) => {
    downNav[num].classList.add("downNavHide");
  };
  //---------------------

  
  const hideMenu = () => {
    document.getElementsByClassName("menuContainer")[0].classList.add("hide");
    document
      .getElementsByClassName("menuContainer")[0]
      .classList.remove("show");
  }
  return (
    <div className="HeaderContainerMain">
      <header>
        <div className="HeaderContainer">
          <div className="menuLogo">
            <img
              src={require('../images/ga-logo.jpg')}
              width="100"
              alt="logo"
            />
          </div>

          <div className="linkBox">
            <div className="logoBox">
              <img
                src={require('../images/ga-logo.jpg')}
                width="150"
                alt="logo"
              />
              {/* <h3>GHAZIABAD AROMATIC</h3> */}
            </div>
            <Link to={"/"} className={active === 'home' ? 'active' : ''}>
              Home
            </Link>

            <div id="mouseOverCountry"

              onMouseEnter={(e) => {
                mouseEnter(0);
              }}
              onMouseLeave={(e) => {
                mouseLeave(0);
              }} className={active === 'company' ? 'active classLink' : ' classLink'}> Company
              <div className="downNav downNavHide ">

                <a href={"/company#about"}>About us</a>
                <a href={"/company#INFRASTRUCTURE"}>INFRASTRUCTURE</a>
                <a href={"/company#global"}> Global Presence </a>
                <a href={"/company#associations"}> Associations </a>
                <a href={"/company#quality"}> Quality Control </a>

              </div>
            </div>

            <Link to={"/products"} className={active === 'products' ? 'active' : ''}  >Products


            </Link>
            {/* <Link to={"/gallery"}  className={active==='gallery'?'active':''} >Gallery</Link> */}


            <Link to={"/contact"} className={active === 'contact' ? 'active' : ''} >Contact us</Link>
          </div>

          <div className="iconBox">
            <a href="tel:+918178948098" className="colorN" >
              <CgPhone /> +91 8178948098
            </a>
            <a href="https://instagram.com/ghaziabadaromatics?igshid=NGVhN2U2NjQ0Yg==" target="_blanck">
              <FaInstagram />
            </a>
            <a href="https://www.linkedin.com/company/ghaziabad-aromatics/" target="_blanck">
              <FaLinkedin />
            </a>
            <a href="https://wa.me/+918178948098" target="_blanck">
              <FaWhatsapp />
            </a>


          </div>
          <div onClick={() => menuHandler()} className="menu">
            <BiMenuAltRight />
          </div>
        </div>


        <div className="menuContainer hide">
          <div>
            <Link to={"/"} onClick={hideMenu} className="active">
              Home
            </Link>
            <Link to={"/products"} onClick={hideMenu} >Products</Link>
            <Link to={"/company"} onClick={hideMenu} >Company</Link>
            <Link to={"/contact"} onClick={hideMenu} >Contact us</Link>
          </div>


          <div className="iconBoxx">
            <a href="tel:+918178948098" className="colorN" >
              <CgPhone /> +91 8178948098
            </a>
            <a href="https://instagram.com/ghaziabadaromatics?igshid=NGVhN2U2NjQ0Yg==" target="_blanck">
              <FaInstagram />
            </a>
            <a href="https://wa.me/+918178948098" target="_blanck">
              <FaWhatsapp />
            </a>
          </div>
        </div>
      </header>

      <Breadcrumbs
        separator={<b> / </b>}
        item={NavLink}
        finalItem={'b'}
        finalProps={{
          style: { color: 'red' }
        }}
      />
    </div>
  );
};

export default Header;
