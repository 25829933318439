import React, { useEffect } from 'react'
import './ProductPage.css'
import OpenModal from '../Components/OpenModal.js'
// import items from '../utils/products'

// import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux'
import { getProductDetails } from '../actions/productAction'
import { Link, useParams } from 'react-router-dom'
import PlantGif from '../images/Plants.gif'
import { HelmetApp } from '../Components/Helmet.js'

const ProductPage = ({ categoryforbreadcrunmbs }) => {

    const [modalIsOpen, setIsOpen] = React.useState(false);

    const dispatch = useDispatch();

    const { name } = useParams()

    const { product, loading, error } = useSelector(
        (state) => state.productDetails
    );



    useEffect(() => {
        let replacedStr = name.replace(/-/g, " ");
        dispatch(getProductDetails(replacedStr))
    }, [dispatch])

    function openModal() {
        setIsOpen(true);
    }







    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    // product?.title ? product?.title : 

    return (
        <>
            <HelmetApp title={ product?.title ? product?.title : `${product?.name} | ${product?.name} Manufacturer | ${product?.name} Manufacturer in Vietnam | ${product?.name} Manufacturer in Turkey | ${product?.name} Manufacturer in Egypt | ${product?.name} Manufacturer in Thailand | ${product?.name} Manufacturer in PERU | ${product?.name} Manufacturer in UAE | ${product?.name} Manufacturer in Poland | ${product?.name} Manufacturer in Russia | ${product?.name} Manufacturer in Mexico | ${product?.name} Manufacturer in Colombia |  ${product?.name} Manufacturer in Ghaziabad`} />

            <div className="productPageContainer">

                <div className='aboutBanner img'>
                    <div>
                    </div>
                </div>

                <div className='breadcrumbs'>
                    {
                        categoryforbreadcrunmbs ? (<span> <Link to={'/'}>Home</Link> / <Link to={'/all-category'}>All Categories</Link> / <Link to={`/category/${categoryforbreadcrunmbs}`}>{categoryforbreadcrunmbs}</Link>/  <Link to={'/products'}>Products</Link> / {product?.tool_name}   </span>) :
                            (
                                <span> <Link to={'/'}>Home</Link> /  <Link to={'/products'}>Products</Link> / {product?.name}  </span>
                            )
                    }
                </div>


                <div className='productBox'>
                    <div className="imgbox">
                        <span>
                            <img src={PlantGif} className='roundImg' alt="image" />
                        </span>

                    </div>
                    <div className="productDetailBox">
                        <span>
                            <h2 className='h2Sty'>{product?.name}</h2>
                        </span>

                        {/* ------------------------------------filed item------------------------------ */}
                        {
                            product?.botanicalName &&
                            <div className='fieldItem'>
                                <h6 className='fHeading'> Botanical Name</h6>
                                <p className='fValue'>{product?.botanicalName}</p>
                            </div>
                        }
                        {
                            product?.plantPart &&
                            <div className='fieldItem'>
                                <h6 className='fHeading'> Plant Part</h6>
                                <p className='fValue'>{product?.plantPart}</p>
                            </div>
                        }
                        {
                            product?.extractionMethod &&
                            <div className='fieldItem'>
                                <h6 className='fHeading'> Extraction Method</h6>
                                <p className='fValue'>{product?.extractionMethod}</p>
                            </div>
                        }
                        {
                            product?.CAS &&
                            <div className='fieldItem'>
                                <h6 className='fHeading'> CAS</h6>
                                <p className='fValue'>{product?.CAS}</p>
                            </div>
                        }
                        {
                            product?.appearance &&
                            <div className='fieldItem'>
                                <h6 className='fHeading'> Appearance</h6>
                                <p className='fValue'>{product?.appearance}</p>
                            </div>
                        }
                        {
                            product?.colour &&
                            <div className='fieldItem'>
                                <h6 className='fHeading'> Colour</h6>
                                <p className='fValue'>{product?.colour}</p>
                            </div>
                        }
                        {
                            product?.odour &&
                            <div className='fieldItem'>
                                <h6 className='fHeading'> Odour</h6>
                                <p className='fValue'>{product?.odour}</p>
                            </div>
                        }
                        {
                            product?.specificGravity &&
                            <div className='fieldItem'>
                                <h6 className='fHeading'> SpecificGravity</h6>
                                <p className='fValue'>{product?.specificGravity}</p>
                            </div>
                        }
                        {
                            product?.refractiveIndex &&
                            <div className='fieldItem'>
                                <h6 className='fHeading'> Refractive Index</h6>
                                <p className='fValue'>{product?.refractiveIndex}</p>
                            </div>
                        }
                        {
                            product?.majorComponents &&
                            <div className='fieldItem'>
                                <h6 className='fHeading'> Major Components</h6>
                                <p className='fValue'>{product?.majorComponents}</p>
                            </div>
                        }
                        {
                            product?.GMOStatus &&
                            <div className='fieldItem'>
                                <h6 className='fHeading'> GMO Status</h6>
                                <p className='fValue'>{product?.GMOStatus}</p>
                            </div>
                        }
                        {
                            product?.packaging &&
                            <div className='fieldItem'>
                                <h6 className='fHeading'>Packaging</h6>
                                <p className='fValue'>{product?.packaging}</p>
                            </div>
                        }
                        {
                            product?.storage &&
                            <div className='fieldItem'>
                                <h6 className='fHeading'> Storage</h6>
                                <p className='fValue'>{product?.storage}</p>
                            </div>
                        }
                        {
                            product?.shelfLife &&
                            <div className='fieldItem'>
                                <h6 className='fHeading'> Shelf Life</h6>
                                <p className='fValue'>{product?.shelfLife}</p>
                            </div>
                        }
                        {
                            product?.applications &&
                            <div className='fieldItem'>
                                <h6 className='fHeading'> Applications</h6>
                                <p className='fValue'>{product?.applications}</p>
                            </div>
                        }
                        {
                            product?.certifications &&
                            <div className='fieldItem'>
                                <h6 className='fHeading'> Certifications</h6>
                                <p className='fValue'>{product?.certifications}</p>
                            </div>
                        }

                        {/* ------------------------------------filed item------------------------------ */}


                        <div className="actionBox">
                            {/* <button className='buybtn'>Buy Now</button> */}
                            <button className='btn1' onClick={openModal}>Enquiry</button>
                            {/* <button className='buybtn' onClick={openModal}>Enquiry</button> */}
                        </div>

                    </div>

                </div>

                <OpenModal modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} item={product} />

            </div>
        </>

    )
}

export default ProductPage;
