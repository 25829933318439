import React from 'react';
import './Category.css';
import { Link } from 'react-router-dom';
import { useParams } from "react-router-dom";
import { clearError, getCategoriesByParent } from '../actions/productAction';
import { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { HelmetApp } from '../Components/Helmet';

const category = [
  {
    name: 'Essential Oils',
    imgUrl: require('../images/certificate/p1.jpg'),
    category: 'essential'
  },

  {
    name: 'Carrier Oils',
    imgUrl: require('../images/certificate/p2.webp'),
    category: 'carrier'

  },

  {
    name: 'Aromatic Chemicals',
    imgUrl: require('../images/certificate/p3.jpg'),
    category: 'aromatic'
  },

  {
    name: 'Flavours',
    imgUrl: require('../images/certificate/p4.jpg'),
    category: 'flavours'
  },

  {
    name: 'Fragrances',
    imgUrl: require('../images/certificate/p5.jpg'),
    category: 'fragrances'
  },

  {
    name: 'Oleoresins',
    imgUrl: require('../images/certificate/p6.jpg'),
    category: 'oleoresins'
  },

  {
    name: 'Plant Extracts',
    imgUrl: require('../images/certificate/p8.webp'),
    category: 'plant-extracts'
  },

]

const CategoryApp = ({ setActive, setCategoryName }) => {
  const { id } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    setActive('products')

  }, [])



  const { categories, loading, error } = useSelector(
    (state) => state.categories
  );
  // console.log(categories)
  useEffect(() => {
    if (error) {
      // toast.error(error)
      dispatch(clearError())
    }
    dispatch(getCategoriesByParent(id))
  }, [dispatch, id, error])


  return (
    <>
      <HelmetApp
        title={'Plant extract manufacturers in Russia | Essential Oils | Carrier Oils'} des={'Our range of products includes essential oils, carrier oils, and plant-extracts. We specialize in creating 100% pure carrier oils that are perfect for skincare and haircare products.'}
      />
      <div className='categoryContainer bgLayer'>
        {/* <BreadcrumbsItem to='/all-category'>all categories / joo</BreadcrumbsItem> */}

        <div className='aboutBanner img3'>
          <div>
            {/* <h1 className=""> 'CategoryName'  </h1> */}
          </div>
        </div>

        <div className='breadcrumbs'>
          <span> <Link to={'/'}>Home</Link> / categories</span>
        </div>

        <div className="headBox ">
          <h2>Products <span className='colorN'> Categories</span></h2>
        </div>
        {/* 
            {
                loading ? (<Loading />) : (<div className="categoryBox rightLayer">

                    {/*  categories && categories.map((item) => ( 
                    <Link to={`/products/essential`} onClick={e => setCategoryName('Essential Oils')} className="all-categoryItem">
                        <img src={require('../images/catpic.png')} alt="" width={'70%'} />
                        <Link to={`/products/essential`}>Essential Oils</Link>
                    </Link>
                    {/*  )) 

                    <Link to={`/products/carrier`} className="all-categoryItem">
                        <img src={'https://static.wixstatic.com/media/79a72f_07acc75a059149808867f42d8f7d364b~mv2_d_4694_3089_s_4_2.jpg/v1/fill/w_640,h_422,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/79a72f_07acc75a059149808867f42d8f7d364b~mv2_d_4694_3089_s_4_2.jpg'} alt="" width={'70%'} />
                        <Link to={`/products/carrier`}>Carrier Oils</Link>
                    </Link>

                    <Link to={`/products/aromatic`} className="all-categoryItem">
                        <img src={'https://5.imimg.com/data5/YA/QE/MY-714555/synthetic-aroma-chemicals-500x500.jpg'} alt="" width={'70%'} />
                        <Link to={`/products/aromatic`}>Aromatic Chemicals</Link>
                    </Link>

                    <Link to={`/products/plant`} className="all-categoryItem">
                        <img src={'https://www.silverlinechemicals.com/images/products/herbal-extracts.jpeg'} alt="" width={'70%'} />
                        <Link to={`/products/plant`}>Plant Extracts</Link>
                    </Link>

                    <Link to={`/products/oleoresins`} className="all-categoryItem">
                        <img src={'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTDxMMO1Zk4DyaYMORKHaIm14dm3egaWIqmk2kOhCeuj-sFrULCLhVw3GaYkInmx_xQVp8&usqp=CAU'} alt="" width={'70%'} />
                        <Link to={`/products/oleoresins`}>Oleoresins</Link>
                    </Link>

                    <Link to={`/products/flavours`} className="all-categoryItem">
                        <img src={'https://www.thespiritsbusiness.com/content/uploads/2018/06/Vodka-flavour.jpg'} alt="" width={'70%'} />
                        <Link to={`/products/flavours`}>Flavours </Link>
                    </Link>

                    <Link to={`/products/fragrances`} className="all-categoryItem">
                        <img src={'https://img.etimg.com/thumb/width-640,height-480,imgsize-59118,resizemode-75,msid-87207008/magazines/panache/fragrances-festivities-apply-perfume-on-pulse-points-opt-for-bold-vanilla-or-oud-scents-this-season/istock-1095533526.jpg'} alt="" width={'70%'} />
                        <Link to={`/products/fragrances`}>Fragrances </Link>
                    </Link>

                </div>)
            } */}
        <div className='cerItemBox categoryBox rightLayer'>
          {
            category?.map((item, i) => (
              <Link to={`/products/${item.category}`} key={i} onClick={e => setCategoryName(item.name)} className="cerItem productItem">
                <img src={item.imgUrl} alt="iso" />
                <br />
                <h2>	{item.name}</h2>
              </Link>
            ))
          }

        </div>

      </div>
    </>
  )
}

export default CategoryApp