import React from 'react'
import './GalleryPage.css'
import { Link } from 'react-router-dom'

const GalleryPage = ({setActive}) => {
    setActive('gallery')
  return (
    <div className='galleryContainer'>
         <div className='aboutBanner img3 img4'>
                <div>
                </div>
            </div>


            <div className="headBox centerN">
                <h2>Our <span className='colorN'> Gallery</span></h2>
                <h4>Ghaziabad Aromatics finds some great memory </h4>
            </div>

            <div className="photosBox">
            </div>

    </div>
  )
}

export default GalleryPage