import React from 'react'
import './AboutUs.css'
import { BsFillArrowRightCircleFill } from 'react-icons/bs'
import AccordionBox from '../Components/AccordionBox.js'
import { HelmetApp } from '../Components/Helmet.js'

const AboutUs = ({ setActive }) => {
  setActive('company')
  return (
    <>
      <HelmetApp title={'Aromatic Chemicals manufacturers in Thailand | Flavours manufacturers in uae | Oleoresins manufacturers in Poland'} des={'Are you looking for high-quality aromatic chemicals, essential oils, and carrier oils? Look no further than Ghaziabad Aromatics! Ghaziabad Aromatics is the go-to manufacturer for all your aromatic needs.'} />

      <div className='aboutContainer bgLayer'>
        <div className='aboutBanner'>
          <div>
            <h1>Company</h1>
          </div>

        </div>

        <div className='' id='about'>
          <div className="section2Container">
            <div className='box1'>
              <h2>About <span className='colorN'>us</span> </h2> <br />
              <h3>“Keep going, <span className='colorN'> natural</span> will find out its ways” </h3>

              <p> <BsFillArrowRightCircleFill /> Driven by the words of our Founder, we aim at providing natural products to the world. Our love for nature and passion towards farmers well being have moved us into the manufacturing of essential oils, aromatic chemicals, extracts and oleoresins, flavours and fragrances. <span className='colorN'>Since 1989</span> , we are focused on providing the very best of nature with every drop.</p>

              <p> <BsFillArrowRightCircleFill /> Our company utilize advanced technologies and expertise to extract, refine, and process natural ingredients into <span className='colorN'>high-quality products</span>. We source raw materials from different parts of the world, including plants, fruits, herbs, and spices, to create a wide range of aromatic and flavourful products.</p>


              <p> <BsFillArrowRightCircleFill /> Our manufacturing process involves carefully selecting and harvesting the raw materials, followed by extraction methods like steam distillation, cold pressing, or solvent extraction to obtain the desired properties and characteristics of the products.</p>

              <p><BsFillArrowRightCircleFill />  We have a team of experienced chemists, perfumers, and flavorists who have in-depth knowledge of scent and taste profiles, enabling them to create unique and customized products based on client requirements. These professionals also conduct research and development activities to explore new ingredients and innovative manufacturing techniques.</p>
            </div>
            <div className='box2'>
              <span>
                <img src={require('../images/gaOwne.jpeg')} alt="product" className='roundImg' />

              </span>
            </div>

          </div>
        </div>

        <div className='section2' id='INFRASTRUCTURE'>
          <div className="section2Container">
            <div className='box1'>
              <h2><span className='colorN'>INFRASTRUCTURE </span> </h2>
              <p>We have 3 manufacturing units in India, where we have the facility of steam distillation, fractional distillation, hydrogenation, reaction vessels etc.</p>
              <p>
                All our raw material and final products are tested for quality. We have an in house lab, well equipped with various testing machines like gas chromatography ( GC), Gas chromatography Mass Spectrometry ( GCMS) with headspace technology, density meter, polarimeter, refractometer etc
              </p>
              {/* <button className='btn1'>Read More</button> */}
            </div>
            <div className='box2'>
              <img className='' style={{ border: '0.5px solid gray', width: '100%' }} src={'https://kapost-files-prod.s3.amazonaws.com/uploads/direct/1485375745-203-1829/usgbc_63049794_Large.jpg'} alt="product" />
            </div>
          </div>
        </div>

        <div className='section2' id='global'>
          <div className="section2Container">
            <div className='box2'>
              {/* <img className='' src={'https://www.planetnatural.com/wp-content/uploads/2023/05/woman-with-flower-toast.jpg'} alt="product" /> */}
              <img src={require('../images/globel.png')} alt="" style={{ border: '0.5px solid gray', width: '100%' }} />
            </div>
            <div className='box1'>
              <h2> Global <span className='colorN'>Presence </span> </h2>
              <p>Successful products for the entire world</p>
              <p>
                We have our foot prints in many countries by exporting our products. If your country is not there, give use chance and we will be more than happy to add the same in our list.
              </p>
              {/* <button className='btn1'>Read More</button> */}

            </div>

          </div>
        </div>

        <div>
          {/* <img src={require('../images/globel.png')} alt="" /> */}
        </div>

        <div className='assContainer bgColor' id='associations'>
          <h2><span className=''>Associations</span></h2>
          <div className="associationBox">

            <div>
              <img src={require('../images/eoai.png')} alt="" />
              <p>ESSENTIAL OIL ASSOCIATION OF INDIA</p>
            </div>
            <div>
              <img src={require('../images/fnf.png')} alt="" className='img' />
              <p>FRAGRANCE AND FLAVOUR ASSOCIATION OF INDIA</p>
            </div>
            <div>
              <img src={require('../images/msme1-.png')} alt="" />
              <p> MINSITRY OF MICRO,SMALL AND MEDIUM ENTERPRISES
                CEHMEXCIL</p>
            </div>
            <div>
              <img src={require('../images/sb-.png')} alt="" />
              <p>SPICES BOARD, MINISTRY OF COMMERCE AND INDUSTRY, GOVERNMENT OF INDIA</p>
            </div>

            {/* <div className='box1'>
          <p>We have 3 manufacturing units in India, where we have the facility of steam distillation, fractional distillation, hydrogenation, reaction vessels etc.</p> 
            <p>
              ESSENTIAL OIL ASSOCIATION OF INDIA <br /> 
              FRAGRANCE AND FLAVOUR ASSOCIATION OF INDIA <br />
              MINSITRY OF MICRO,SMALL AND MEDIUM ENTERPRISES 
              CEHMEXCIL<br />
              SPICES BOARD, MINISTRY OF COMMERCE AND INDUSTRY, GOVERNMENT OF INDIA <br />
            </p>
          <button className='btn1'>Read More</button> 

          </div> */}

          </div>
        </div>

        <div className="accordionContainer" id='quality'>
          <h1>Quality Control <span className='colorN'>  : A vital aspect.</span> </h1>
          <AccordionBox />
          {/* <button className='btn2'>More</button> */}
        </div>

      </div>
    </>

  )
}

export default AboutUs