
const IframeComponent = () => {

    return (
      <>
          
        <a href="https://maps.app.goo.gl/SN8qUH6N39moswCN6" rel="noreferrer" target='_blank'>
          <img src={require('../images/certificate/ga-map.png')} width={'100%'} alt="" />
        </a>
      </>
    );
  };
  
  export default IframeComponent;