import React, { useState } from 'react';
import { FaTwitter, FaFacebook, FaInstagram, FaMagic, FaMailBulk, FaPhone } from 'react-icons/fa';
import './Contact.css'
import { Connection_Url } from '../utils/productionvariable'
import axios from 'axios'
import { BsClock } from 'react-icons/bs';
import { ImLocation2 } from 'react-icons/im';
import { MdOutlinePhonelinkRing, MdAlternateEmail } from 'react-icons/md';
import { HelmetApp } from '../Components/Helmet';
import MapIframe from '../Components/iFrameMap';

const Contact = ({ setActive }) => {
    setActive("contact")

    const [name, setName] = useState("");
    const [email, setemail] = useState("");
    const [topic, settopic] = useState("");
    const [message, setmessage] = useState("");

    const [isTrue, setIsTrue] = useState("");

    const formSubmitHandler = async (e) => {
        e.preventDefault();
        // console.log(name, email, rating, dob)
        const data = {
            name: name, sub: topic, email, message
        }
        document.getElementById("loading").innerHTML = "Please wait..."
        setIsTrue(true)

        const url = `${Connection_Url}/api/v1/contact`
        await axios.post(url, data,
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    Accept: 'application/json'
                }
            }
        ).then((res) => {
            alert("Thankyou for Contact us")
            window.location.reload()
        }).catch((error) => {
            document.getElementById("loading").innerHTML = ''
            alert(`Technical Error. ${error}`)
            // window.location.reload()
        })
    }


    return (
        <div>
            <HelmetApp title={'Contact us | Ghaziabadaromatics | Ghaziabad Aromatics'} des={"When it comes to finding the best products, look no further. Come to ghaziabad aromatics and see what we have to offer for you. Contact our website for more information."} />
            <div className="infoContainer">
                <div className="infoBox1">
                    <h1>Send us <span className='colorN'> a message </span></h1>
                    {/* <div className="formContainer"> */}
                    <form onSubmit={formSubmitHandler}>
                        <div className="formBox">
                            <div>
                                <input type="text" placeholder='First Name' required value={name} onChange={e => setName(e.target.value)} />
                            </div>

                            <div>
                                <input type="email" placeholder='Email' value={email} onChange={e => setemail(e.target.value)} />
                            </div>
                            <div>
                                <input type="text" placeholder='Subject' value={topic} onChange={e => settopic(e.target.value)} />
                            </div>
                            <div className='textAreaBox'>
                                {/* <label htmlFor="">Message</label> */}
                                <textarea placeholder='Type your Messege' cols="20" rows="5" value={message} onChange={e => setmessage(e.target.value)}></textarea>
                            </div>
                            <p id='loading'></p>
                            <input className='submitBtn' type="submit" disabled={isTrue} />
                        </div>
                    </form>
                </div>
                {/* </div> */}
                <div className="infoBox2">
                    <h1> Contact <span className='colorN'> us </span> </h1>
                    <div className='i2Box'>
                        <div>
                            <BsClock />
                            <h2>Working Hour</h2>
                            <p>MON-SAT | 9am to 6pm</p>
                            <p>SUN Closed</p>
                        </div>
                        <div>
                            <ImLocation2 />
                            <h2>Location</h2>
                            <p> Factory address: A-2/11 Sector 17, Kavinagar Industrial Area, Ghaziabad, Uttar Pradesh</p>
                            {/* <p>SUN | 9am to 5pm</p> */}
                        </div>
                    </div>
                    <div className='i2Box'>
                        <div>
                            <MdOutlinePhonelinkRing />
                            <h2>Contact</h2>
                            <p>+91 8178948098</p>
                            <p>Give us a call 24/7</p>
                        </div>

                        <div>
                            <MdAlternateEmail />
                            <h2>Email us</h2>
                            <p className='ptag1'> <a rel="noreferrer" target='_blank' href={"mailto:sales@ghaziabadaromatics.com"}>  sales@ghaziabadaromatics.com</a></p>

                            <p className='ptag1'> <a rel="noreferrer" target='_blank' href={"mailto:info@ghaziabadaromatics.com"}>  info@ghaziabadaromatics.com</a></p>
                            {/* <p>SUN | 9am to 5pm</p> */}
                        </div>
                    </div>

                </div>
            </div>
            <div className="mapContainer">
                
                {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7001.898695851313!2d77.45453714113873!3d28.661235266869195!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cf1efe3dd2bb7%3A0xae882e315a37a557!2sKavi%20Nagar%20Industrial%20Area%2C%20Sector%2017%2C%20Kavi%20Nagar%2C%20Ghaziabad%2C%20Uttar%20Pradesh%20201002!5e0!3m2!1sen!2sin!4v1717662725622!5m2!1sen!2sin" className='Iframe' loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                <MapIframe />

                <div className='iframeContactContainer'>
                    <div className="iframContactBox">
                        <img src="https://sensientessentialoils.com/wp-content/uploads/2019/07/Cedar-Leaf-Oil-min-scaled.jpg" alt="" />
                        <div>
                            <img src={require('../images/ga-logo.jpg')} alt="Logo" width={100} className='logo' />

                            <div>
                                <p><FaPhone /> +91 8178948098</p>
                                <p><FaMailBulk />  sales@ghaziabadaromatics.com
                                </p>
                                <p><ImLocation2 />Kavinagar Industrial Area, Ghaziabad.</p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact