
export const ALL_PRODUCT_REQUEST = "ALL_PRODUCT_REQUEST"
export const ALL_PRODUCT_SUCCESS = "ALL_PRODUCT_SUCCESS"
export const ALL_PRODUCT_FAIL = "ALL_PRODUCT_FAIL"

export const PRODUCT_DETAILS_REQUEST = "PRODUCT_DETAILS_REQUEST"
export const PRODUCT_DETAILS_SUCCESS = "PRODUCT_DETAILS_SUCCESS"
export const PRODUCT_DETAILS_FAIL = "PRODUCT_DETAILS_FAIL"

export const GET_CATEGORIES_REQUEST = "GET_CATEGORIES_REQUEST"
export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS"
export const GET_CATEGORIES_FAIL = "GET_CATEGORIES_FAIL"

export const GET_CATEGORIES_P_REQUEST = "GET_CATEGORIES_P_REQUEST"
export const GET_CATEGORIES_P_SUCCESS = "GET_CATEGORIES_P_SUCCESS"
export const GET_CATEGORIES_P_FAIL = "GET_CATEGORIES_P_FAIL"

export const CLEAR_ERROR = "CLEAR_ERROR"