import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Header from '../src/Components/Header.js';
import Footer from '../src/Components/Footer.js';

import Home from '../src/Pages/Home.js';
import NotPageFound from '../src/Pages/NotPageFound.js';
import ProductPage from '../src/Pages/ProductPage.js';
import Products from '../src/Pages/Products.js';
import AboutUs from '../src/Pages/AboutUs.js';
import GalleryPage from '../src/Pages/GalleryPage.js';
import  ContactUs from '../src/Pages/Contact';
import AllCategory from '../src/Pages/AllCategory.js';
import { useState } from 'react';


function App() {
  const [search, setSearch] = useState('')
  const [categoryforbreadcrunmbs, setcategoryforbreadcrunmbs] = useState('')
  
  const [CategoryName, setCategoryName] = useState('')

  const [active, setActive] = useState('home');

  return (
    <BrowserRouter>
      <Header search={search} setSearch={setSearch} active={active} />
      <Routes>
        <Route path="/" element={<Home setActive={setActive} setCategoryName={setCategoryName} />} /> 
        <Route path="/products" element={<AllCategory setActive={setActive}  setCategoryName={setCategoryName} />} />
        <Route path="/product/:name" element={<ProductPage categoryforbreadcrunmbs={categoryforbreadcrunmbs} />} />
        <Route path="/products/:category" element={<Products setSearch={setSearch} CategoryName={CategoryName} />} />
        <Route path="/contact" element={<ContactUs setActive={setActive} />} />
        <Route path="/company" element={<AboutUs setActive={setActive} />} />
        <Route path="/gallery" element={<GalleryPage setActive={setActive} />} />
        <Route path="*" element={<NotPageFound  />} />

      </Routes>
      <Footer />

    </BrowserRouter>
  );
}

export default App;
