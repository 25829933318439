import axios from "axios";
import {
  ALL_PRODUCT_REQUEST,
  ALL_PRODUCT_FAIL,
  ALL_PRODUCT_SUCCESS,

  PRODUCT_DETAILS_FAIL,
  PRODUCT_DETAILS_SUCCESS,
  PRODUCT_DETAILS_REQUEST,
  

  CLEAR_ERROR,
  GET_CATEGORIES_REQUEST,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_FAIL,
  GET_CATEGORIES_P_REQUEST,
  GET_CATEGORIES_P_SUCCESS,
  GET_CATEGORIES_P_FAIL,
} from "../constants/productConstants";
// const host = 'http://localhost:9001'
const host = "https://ghaziabadaromatics.com"
// import host from '../utils/productionvariable'



export const getProducts = ( category  ) => async (dispatch) => {

  try {
    dispatch({ type: ALL_PRODUCT_REQUEST });
    // console.log("error:",link)
    let link = `${host}/api/v1/products`;
    
    if(category){
      // console.log("category",category)
       link = `${host}/api/v1/products?category=${category}`;
    }
    const { data } = await axios.get(link);
    // console.log("data: ",link);

    dispatch({
      type: ALL_PRODUCT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ALL_PRODUCT_FAIL,
      payload: error.response.data.message,
    });
  }
};


export const getProductDetails = (name) => async (dispatch) => {
  // console.log("id",id)
 
    try {
      dispatch({ type: PRODUCT_DETAILS_REQUEST });
      const { data } = await axios.get(`${host}/api/v1/product/${name}`);
      dispatch({
        type: PRODUCT_DETAILS_SUCCESS,
        payload: data.product,
      });
    } catch (error) {
      dispatch({
        type: PRODUCT_DETAILS_FAIL,
        payload: error.response.data.message,
      });
    }
  };

  export const getCategories = () => async (dispatch) => {
   
      try {
        dispatch({ type: GET_CATEGORIES_REQUEST });
        const { data } = await axios.get(`${host}/api/v1/categories`);
        dispatch({
          type: GET_CATEGORIES_SUCCESS,
          payload: data.categories,
        });
      } catch (error) {
        dispatch({
          type: GET_CATEGORIES_FAIL,
          payload: error.response.data.message,
        });
      }
    };
  
    export const getCategoriesByParent = (id) => async (dispatch) => {
   
      try {
        dispatch({ type: GET_CATEGORIES_P_REQUEST });
        const { data } = await axios.get(`${host}/api/v1/subcategories/${id}`);
        dispatch({
          type: GET_CATEGORIES_P_SUCCESS,
          payload: data.subcategories,
        });
      } catch (error) {
        dispatch({
          type: GET_CATEGORIES_P_FAIL,
          payload: error.response.data.message,
        });
      }
    };
// clearing error
export const clearError = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERROR });
};
