import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
// import {composeWithDevTools} from 'redux-devtools-extension';

import {    getAllCategories, getCategoriesByP, productDetailsReducers, productReducers } from './reducers/productReducer'


const reducer = combineReducers({
        productDetails: productDetailsReducers, 
       products:productReducers,
       categories:getAllCategories,
       categoriesByP:getCategoriesByP
});

let intitialeState = {
    
}; 

const middleware = [thunk]

const store = createStore(
    reducer,
    intitialeState,
    applyMiddleware(...middleware),
    // composeWithDevTools(applyMiddleware(...middleware))
)

export default store;
