import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import store from './store';
import { Provider } from "react-redux";
import { ThroughProvider } from 'react-through'
import { hydrate ,render} from 'react-dom';
// import {  } from 'react-dom';
// import { hydrateRoot } from 'react-dom/client';
// import { hydrateRoot } from 'react-dom/client';


// step 1
// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render( // hydrate -> render
//   <Provider store={store}>
//     <ThroughProvider>
//       <App />
//     </ThroughProvider>
//   </Provider>
// );

// step 2
// const container = document.getElementById('root')
// hydrateRoot(container, <App/>)

// step 3
const Root = (
    <Provider store={store} >
        <ThroughProvider>
            <App />
        </ThroughProvider>
    </Provider >
);

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(Root, rootElement);
} else {
  render(Root, rootElement);
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
