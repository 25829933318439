import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import './OpenModal.css'
import axios from 'axios';
import { Connection_Url } from '../utils/productionvariable'

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

const OpenModal = ({ modalIsOpen, setIsOpen, item }) => {

    let subtitle;
    //   const [modalIsOpen, setIsOpen] = React.useState(false);


    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setmessage] = useState("");

    const [isTrue, setIsTrue] = useState(false);

    // const alert = useAlert();

    const formSubmitHandler = async (e) => {
        e.preventDefault();
        // console.log(name, email, rating, dob)
        const data = {
            name, email, phone, message, item: {
                'Product Name': item?.name
            }
        }


        document.getElementById("loading").innerHTML = "Please wait..."
        setIsTrue(true)

        const url = `${Connection_Url}/api/v1/product/info`
        await axios.post(url, data,
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    Accept: 'application/json'
                }
            }
        ).then((res) => {
            alert("Email Send SuccussFully. Thank-you for Join us")
            window.location.reload()

        }).catch((error) => {

            alert(`Technical Error. ${error}`)
            window.location.reload()


        })


    }

    return (
        <div className='modalContainer'>
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div className="box2">
                    {/* <h2 >Enquiry</h2> */}
                    <h2 className='colorN'>Enquiry / {item?.name}</h2>
                    <form action="" className='' onSubmit={formSubmitHandler}>
                        <input className='inpStyle' type="name" required placeholder='Name' value={name} onChange={(e) => setName(e.target.value)} />
                        {/* <p className='doblable'>Select date of Birth </p>
                        <input type="date" className='dob' required placeholder='' value={dob} onChange={(e) => setDob(e.target.value)} /> */}
                        <input type="email" className='inpStyle' required placeholder='Email' value={email} onChange={(e) => setEmail(e.target.value)} />
                        <input type="number" className='inpStyle' required placeholder='Phone' value={phone} onChange={(e) => setPhone(e.target.value)} />
                        <textarea type="number" className='inpStyle' required placeholder='Mesage' value={message} onChange={(e) => setmessage(e.target.value)} />

                        {/* <select id="product" value={product} onChange={(e) => setProduct(e.target.value)} className='inpStyle' required name="Product">
                            <option value="">Product you bought</option>
                            <option value="carrier oils">Carrier oils</option>
                            <option value="essential oil">Essential oils</option>
                            <option value="fragrance oil"> Fragrance oils</option>
                            // {/* <option value=" fragrance oil"> Purpose Oils</option> 
                            <option value=" fragrance oil"> Nothing yet</option>
                        </select> */}



                        <p id='loading'></p>
                        <div className='actionBox'>

                            <input type="submit" className='FormSectionBtn' disabled={isTrue} />
                            <button onClick={closeModal} className='close' >close</button>
                        </div>

                    </form>
                </div>

            </Modal>
        </div>
    );
}

// ReactDOM.render(<App />, appElement);
export default OpenModal

