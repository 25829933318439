import {
  ALL_PRODUCT_FAIL,
  ALL_PRODUCT_REQUEST,
  ALL_PRODUCT_SUCCESS,


  PRODUCT_DETAILS_FAIL,
  PRODUCT_DETAILS_SUCCESS,
  PRODUCT_DETAILS_REQUEST,
  CLEAR_ERROR,
  GET_CATEGORIES_REQUEST,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_FAIL,
  GET_CATEGORIES_P_REQUEST,
  GET_CATEGORIES_P_SUCCESS,
  GET_CATEGORIES_P_FAIL,


} from "../constants/productConstants";


export const productReducers = ( state = { product: [] }, action ) => {

    switch (action.type) {
        case ALL_PRODUCT_REQUEST:
            return{
                loading:true,
                product:[]
            }
        case ALL_PRODUCT_SUCCESS:
            return{
                loading:false,
                products:action.payload.products,
                productCount:action.payload.productCount,
                resultPerPage:action.payload.resultPerPage,
                filterProductCount:action.payload.filterProductCount

            }

  

        case ALL_PRODUCT_FAIL:
            return{
                loading:false,
                error:action.payload
            }
        case CLEAR_ERROR:
            return{
                ...state,
                error:null
            }
    
        default:
            return state;
    }
};


export const productDetailsReducers = ( state = { product: {} }, action ) => {

    switch (action.type) {
        case  PRODUCT_DETAILS_REQUEST:
            return{
                loading:true,
                ...state
            }
        case PRODUCT_DETAILS_SUCCESS:
            return{
                loading:false,
                product:action.payload,
            }
        case PRODUCT_DETAILS_FAIL:
            return{
                loading:false,
                error:action.payload
            }
        case CLEAR_ERROR:
            return{
                ...state,
                error:null
            }
    
        default:
            return state;
    }
};


export const getAllCategories= ( state = { categories: [] }, action ) => {

    switch (action.type) {
        case  GET_CATEGORIES_REQUEST:
            return{
                loading:true,
                ...state
            }
        case GET_CATEGORIES_SUCCESS:
            return{
                loading:false,
                categories:action.payload,
            }
        case GET_CATEGORIES_FAIL:
            return{
                loading:false,
                error:action.payload
            }
        case CLEAR_ERROR:
            return{
                ...state,
                error:null
            }
    
        default:
            return state;
    }
};

export const getCategoriesByP= ( state = { categories: [] }, action ) => {

    switch (action.type) {
       
            case  GET_CATEGORIES_P_REQUEST:
            return{
                loading:true,
                ...state
            }

            case GET_CATEGORIES_P_SUCCESS:
            return{
                loading:false,
                categories:action.payload,
            }
            case GET_CATEGORIES_P_FAIL:
            return{
                loading:false,
                error:action.payload
            }
        case CLEAR_ERROR:
            return{
                ...state,
                error:null
            }
    
        default:
            return state;
    }
};



